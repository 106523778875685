import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';

import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core';
import { getLibrary } from './utils/web3React';
import { BankVaultContextProvider } from './contexts/BankVaultContext';
import { RefreshContextProvider } from './contexts/RefreshContext';
import { NetworkContextName } from './config/constants/misc';
import store from './state';
import Web3ReactManager from './components/Web3ReactManager';
import { MoralisProvider } from 'react-moralis';
import { useActiveWeb3React } from './hooks/useWeb3';
import { getMoralisAppId, getMoralisServerUrl } from './utils/moralisHelper';
import { PendingTransactionContextProvider } from './contexts/PendingTransactionContext';

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);
const Providers = ({ children }) => {
  const [moralisAppId, setMoralisAppId] = useState();
  const [serverUrl, setMoralisServerUrl] = useState();
  const { chainId } = useActiveWeb3React();

  useEffect(() => {
    setMoralisAppId(getMoralisAppId(chainId));
  }, [chainId, setMoralisAppId]);

  useEffect(() => {
    setMoralisServerUrl(getMoralisServerUrl(chainId));
  }, [chainId, setMoralisServerUrl]);

  return (
    <Provider store={store}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ProviderNetwork getLibrary={getLibrary}>
          <Web3ReactManager>
            <PendingTransactionContextProvider>
              <RefreshContextProvider>
                <MoralisProvider appId={moralisAppId} serverUrl={serverUrl}>
                  <BankVaultContextProvider>{children}</BankVaultContextProvider>
                </MoralisProvider>
              </RefreshContextProvider>
            </PendingTransactionContextProvider>
          </Web3ReactManager>
        </Web3ProviderNetwork>
      </Web3ReactProvider>
    </Provider>
  );
};

export default Providers;

export const ENS_REGISTRAR_ADDRESSES = {
  1: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  4: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
};

export const MULTICALL_ADDRESSES = {
  1: '',
  4: '0xD56B6B01dB71d39feeA7439F1D8139467d7fffc7',
  43113: '0x965F6db159E4A94c82f36f5F80eD17e600104BD3',
  43114: '0x8be4CB8cc385C9aDE8b307753cC68aBcFcf4cDCE'
};

/**************************
 ** NFT market addresses
 *************************/
export const DRAGON_PLAYER_CHARACTER = {
  4: '0xa48E72e5B139c048B793D7f5530264b228ff8A48'
};

export const DRAGON_EQUIPMENT = {
  4: '0x67a2364EbB4232940f04a49BE82d3030C1Cd122E'
};

export const DRAGON_RESOURCE = {
  4: '0x69E977Ad4A45c4bE5f733E68B80f9f58cd27c893'
};

/**
 * @todo should be in .env
 */
export const MORALIS_SERVER_URL = {
  4: 'https://ad82l8ejjfjk.usemoralis.com:2053/server'
};

export const MORALIS_APP_ID = {
  4: 'VnicuoFsKZxPITargVBL4qPln4cvCWkKSIVjDrmZ'
};

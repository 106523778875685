import { MORALIS_APP_ID, MORALIS_SERVER_URL } from '../config/constants/addresses';

export const getMoralisAppId = (chainId) => {
  if (chainId && Object.keys(MORALIS_APP_ID).includes(chainId)) {
    return MORALIS_APP_ID[chainId];
  }
  return MORALIS_APP_ID[4];
};

export const getMoralisServerUrl = (chainId) => {
  if (chainId && Object.keys(MORALIS_SERVER_URL).includes(chainId)) {
    return MORALIS_SERVER_URL[chainId];
  }
  return MORALIS_SERVER_URL[4];
};

export const getNFTItems = async (moralisAccount, options) => {
  return await moralisAccount.getNFTsForContract(options);
};

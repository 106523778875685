import { ethers } from 'ethers';
import { getAddress } from '@ethersproject/address';

import { VAULT_CHEF_ADDRESSES, MASTER_CHEF_ADDRESSES, POOLS, DCAU_ADDRESSES, DCAU_USDTe_PIAR_ADDRESSES, USDTe_ADDRESSES } from '../config/pools';
import { CHAINS } from '../config/constants/chains';
import { MULTICALL_ADDRESSES } from '../config/constants/addresses';

export const ADDRESS_ZERO = ethers.constants.AddressZero;

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value) {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

export function formatAddress(value) {
  const address = isAddress(value);
  if (address === false) {
    return null;
  }
  const prefix = address.substring(0, 6); // 0x1234
  const suffix = address.substring(38, 42); // ...abcd
  return `${prefix}...${suffix}`;
}

export const getENSName = (chainId) => {
  return CHAINS[chainId];
};

export const getVaultChefAddress = (chainId) => VAULT_CHEF_ADDRESSES[chainId];

export const getMasterChefAddress = (chainId) => MASTER_CHEF_ADDRESSES[chainId];

export const getPoolAddress = (chainId, mcPid, poolId) => {
  poolId = POOLS[getENSName(chainId)].findIndex((p) => p.mcPid === mcPid && p.pid === poolId);
  return POOLS[getENSName(chainId)][poolId].address;
};

export const getMulticallAddress = (chainId) => MULTICALL_ADDRESSES[chainId];

export const getDCAUAddress = (chainId) => DCAU_ADDRESSES[chainId];

export const getDcauUsdteAddress = (chainId) => DCAU_USDTe_PIAR_ADDRESSES[chainId];

export const getUSDTeAddress = (chainId) => USDTe_ADDRESSES[chainId];

import React from 'react';

const initialState = { transactions: 0, addTransaction: () => null, removeTransaction: () => null };
const PendingTransactionContext = React.createContext(initialState);
export const usePendingTransactionContext = () => React.useContext(PendingTransactionContext);

export const PendingTransactionContextProvider = ({ children }) => {
  const [transactions, setTransactions] = React.useState(0);
  const addTransaction = () => {
    setTransactions(transactions + 1);
  };
  const removeTransaction = () => {
    if (transactions > 0) setTransactions(transactions - 1);
    else setTransactions(0);
  };

  return (
    <PendingTransactionContext.Provider value={{ transactions, addTransaction, removeTransaction }}>{children}</PendingTransactionContext.Provider>
  );
};

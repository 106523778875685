export const VAULT_CHEF_ADDRESSES = {
  4: '0x9ab31c04989CC6C2C9398a5A6Ae8a9d99Aeaf3B9',
  43113: '0x1b73c97DDA679B6486f6A200b1d5260f542F675A',
  43114: '0xdEAE788795Bbc8126E7532EB94B7e6b9E5960cfF'
};

export const MASTER_CHEF_ADDRESSES = {
  4: '0x9ab31c04989CC6C2C9398a5A6Ae8a9d99Aeaf3B9',
  43113: '0x79497DeA2A2ea053549b27445a1CD50d08FDC5ea',
  43114: '0x0be0d3d0c3a122b7f57b6119766880a83f95ae9f'
};

export const DRAGON_NEST_SUPPORTER_ADDRESSES = {
  4: '0x78BBA461A395B4C9Eda909D34fd5608fe6417B12',
  43113: '0x500BFd39bF7e7E19a5342d24B6C6E70017793A55',
  43114: '0x253f2BF92AA76e0E448A77ED98197f44EeC96a9F'
};

export const DCAU_ADDRESSES = {
  4: '0x7dd5DF8396B40948fF1373A29Ae097329D12B643',
  43113: '0xF72Cc18218058722a3874b63487F1B4C82F92081',
  43114: '0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30'
};

export const BURN_ADDRESS = '0x000000000000000000000000000000000000dEaD';

export const DCAU_USDTe_PIAR_ADDRESSES = {
  4: '0x2408fa4f70d03a9acc8f03ef3cd538aac1d0455f',
  43113: '0x6BF04678E2a0Cb9dBEb41bf3e4FD7B0918DE0301',
  43114: '0x605AAC9F0024A019F328074909874E3EE510f377'
};

export const USDTe_ADDRESSES = {
  4: '0x2a76583690dB7ED7D1f977100fB4b369c536195B',
  43113: '0x3eDCd20436B4485a8F8Ee107aC8e33855ac771A8',
  43114: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118'
};

export const POOLS = {
  rinkeby: [
    {
      mcPid: 0,
      pid: 0,
      address: '0x7dd5DF8396B40948fF1373A29Ae097329D12B643',
      single: true,
      name: 'DCAU - SINGLE',
      vault: false,
      strategyAddress: '0x0000000000000000000000000000000000000000',
      quoteTokenAddress: '0x7dd5DF8396B40948fF1373A29Ae097329D12B643',
      quoteStablePairAddress: '0x2408fa4f70d03a9acc8f03ef3cd538aac1d0455f',
      stableTokenAddress: '0x2a76583690dB7ED7D1f977100fB4b369c536195B',
      logo: 'assets/images/tokens/dcau.png'
    },
    {
      mcPid: 1,
      pid: 1,
      address: '0xaecd5D251E058CBf225f763Fb852d442133d6D33',
      single: true,
      name: 'WAVAX - SINGLE',
      vault: false,
      strategyAddress: '0x0000000000000000000000000000000000000000',
      quoteTokenAddress: '0x7dd5DF8396B40948fF1373A29Ae097329D12B643',
      quoteStablePairAddress: '0x2408fa4f70d03a9acc8f03ef3cd538aac1d0455f',
      stableTokenAddress: '0x2a76583690dB7ED7D1f977100fB4b369c536195B',
      logo: 'assets/images/tokens/wavax.png'
    },
    {
      mcPid: 2,
      pid: 2,
      address: '0x051ecb5dab5ea1ff9cbcd54b3f64ba137c4e994f',
      single: true,
      name: 'WBTC.e - SINGLE',
      vault: false,
      strategyAddress: '0x0000000000000000000000000000000000000000',
      quoteTokenAddress: '0x7dd5DF8396B40948fF1373A29Ae097329D12B643',
      quoteStablePairAddress: '0x2408fa4f70d03a9acc8f03ef3cd538aac1d0455f',
      stableTokenAddress: '0x2a76583690dB7ED7D1f977100fB4b369c536195B',
      logo: 'assets/images/tokens/wbtc.e.png'
    },
    {
      mcPid: 3,
      pid: 3,
      address: '0xd89Af40284004C13739495D2e2Cf45F57F6bec60',
      single: true,
      name: 'WETH.e - SINGLE',
      vault: false,
      strategyAddress: '0x0000000000000000000000000000000000000000',
      quoteTokenAddress: '0x7dd5DF8396B40948fF1373A29Ae097329D12B643',
      quoteStablePairAddress: '0x2408fa4f70d03a9acc8f03ef3cd538aac1d0455f',
      stableTokenAddress: '0x2a76583690dB7ED7D1f977100fB4b369c536195B',
      logo: 'assets/images/tokens/weth.e.png'
    },
    {
      mcPid: 4,
      pid: 4,
      address: '0x2a76583690dB7ED7D1f977100fB4b369c536195B',
      single: true,
      name: 'USDT.e - SINGLE',
      vault: false,
      strategyAddress: '0x0000000000000000000000000000000000000000',
      quoteTokenAddress: '0x7dd5DF8396B40948fF1373A29Ae097329D12B643',
      quoteStablePairAddress: '0x2408fa4f70d03a9acc8f03ef3cd538aac1d0455f',
      stableTokenAddress: '0x2a76583690dB7ED7D1f977100fB4b369c536195B',
      logo: 'assets/images/tokens/usdt.e.png'
    },
    {
      mcPid: 5,
      pid: 5,
      address: '0xe4fc4BF4515dE2f0B5f8cc238b5C38D2dA333d68',
      single: true,
      name: 'JOE - SINGLE',
      vault: false,
      strategyAddress: '0x0000000000000000000000000000000000000000',
      quoteTokenAddress: '0x7dd5DF8396B40948fF1373A29Ae097329D12B643',
      quoteStablePairAddress: '0x2408fa4f70d03a9acc8f03ef3cd538aac1d0455f',
      stableTokenAddress: '0x2a76583690dB7ED7D1f977100fB4b369c536195B',
      logo: 'assets/images/tokens/joe.png'
    },
    {
      mcPid: 6,
      pid: 6,
      address: '0x6b273a2268db806b21F59850f7BDec6f663d28bb',
      single: true,
      name: 'MIM - SINGLE',
      vault: false,
      strategyAddress: '0x0000000000000000000000000000000000000000',
      quoteTokenAddress: '0x7dd5DF8396B40948fF1373A29Ae097329D12B643',
      quoteStablePairAddress: '0x2408fa4f70d03a9acc8f03ef3cd538aac1d0455f',
      stableTokenAddress: '0x2a76583690dB7ED7D1f977100fB4b369c536195B',
      logo: 'assets/images/tokens/mim.png'
    },
    {
      mcPid: 7,
      pid: 7,
      address: '0x7646f074970DdF15269fA85A276DBe3CcD67c0ea',
      single: true,
      name: 'SPELL - SINGLE',
      vault: false,
      strategyAddress: '0x0000000000000000000000000000000000000000',
      quoteTokenAddress: '0x7dd5DF8396B40948fF1373A29Ae097329D12B643',
      quoteStablePairAddress: '0x2408fa4f70d03a9acc8f03ef3cd538aac1d0455f',
      stableTokenAddress: '0x2a76583690dB7ED7D1f977100fB4b369c536195B',
      logo: 'assets/images/tokens/spell.png'
    },
    {
      mcPid: 8,
      pid: 8,
      address: '0xB74F1cCA355a65e040a34088e5DdbcBD9E2DA606',
      single: true,
      name: 'TIME - SINGLE',
      vault: false,
      strategyAddress: '0x0000000000000000000000000000000000000000',
      quoteTokenAddress: '0x7dd5DF8396B40948fF1373A29Ae097329D12B643',
      quoteStablePairAddress: '0x2408fa4f70d03a9acc8f03ef3cd538aac1d0455f',
      stableTokenAddress: '0x2a76583690dB7ED7D1f977100fB4b369c536195B',
      logo: 'assets/images/tokens/time.png'
    }
  ],
  avaxfuji: [
    {
      mcPid: 0,
      pid: 0,
      address: '0x898b7528BFD18fFb58998D281C3B3F14F9c6CEf2',
      single: false,
      name: 'DCAU/WAVAX',
      vault: true,
      strategyAddress: '0x509cB30fE24055d03D6f083c1813769EF778A3c1',
      quoteTokenAddress: '0xF72Cc18218058722a3874b63487F1B4C82F92081',
      quoteStablePairAddress: '0x6BF04678E2a0Cb9dBEb41bf3e4FD7B0918DE0301',
      stableTokenAddress: '0x3eDCd20436B4485a8F8Ee107aC8e33855ac771A8',
      tokenPair: '0xF72Cc18218058722a3874b63487F1B4C82F92081/0x02E8deC98398F747f079E5a76DddE72cd1A49D9a',
      logo: 'assets/images/tokens/dcau-wavax.png'
    },
    {
      mcPid: 1,
      pid: 1,
      address: '0x6BF04678E2a0Cb9dBEb41bf3e4FD7B0918DE0301',
      single: false,
      name: 'DCAU/USDT.e',
      vault: true,
      strategyAddress: '0x3385ec3487e37Cb172e9c8342842fFba7222C01E',
      quoteTokenAddress: '0xF72Cc18218058722a3874b63487F1B4C82F92081',
      quoteStablePairAddress: '0x6BF04678E2a0Cb9dBEb41bf3e4FD7B0918DE0301',
      stableTokenAddress: '0x3eDCd20436B4485a8F8Ee107aC8e33855ac771A8',
      tokenPair: '0xF72Cc18218058722a3874b63487F1B4C82F92081/0x3eDCd20436B4485a8F8Ee107aC8e33855ac771A8',
      logo: 'assets/images/tokens/dcau-usdt.e.png'
    },
    {
      mcPid: 2,
      pid: 2,
      address: '0x675617dD8Abb94bb24DA9cb34cCE93aA936c1307',
      single: false,
      name: 'DCAU/MIM',
      vault: true,
      strategyAddress: '0xB1F7b1d9C2D9072E022F02826775DB6ca4571eeF',
      quoteTokenAddress: '0xF72Cc18218058722a3874b63487F1B4C82F92081',
      quoteStablePairAddress: '0x6BF04678E2a0Cb9dBEb41bf3e4FD7B0918DE0301',
      stableTokenAddress: '0x3eDCd20436B4485a8F8Ee107aC8e33855ac771A8',
      tokenPair: '0xF72Cc18218058722a3874b63487F1B4C82F92081/0x8Dd3B7bC1E226A96daD12490c4D0f11FFF179D11',
      logo: 'assets/images/tokens/dcau-mim.png'
    },
    {
      mcPid: 0,
      pid: 0,
      address: '0x898b7528BFD18fFb58998D281C3B3F14F9c6CEf2',
      single: false,
      name: 'DCAU/WAVAX',
      vault: false,
      strategyAddress: '0x509cB30fE24055d03D6f083c1813769EF778A3c1',
      quoteTokenAddress: '0xF72Cc18218058722a3874b63487F1B4C82F92081',
      quoteStablePairAddress: '0x6BF04678E2a0Cb9dBEb41bf3e4FD7B0918DE0301',
      stableTokenAddress: '0x3eDCd20436B4485a8F8Ee107aC8e33855ac771A8',
      tokenPair: '0xF72Cc18218058722a3874b63487F1B4C82F92081/0x02E8deC98398F747f079E5a76DddE72cd1A49D9a',
      logo: 'assets/images/tokens/dcau-wavax.png'
    },
    {
      mcPid: 1,
      pid: 1,
      address: '0x6BF04678E2a0Cb9dBEb41bf3e4FD7B0918DE0301',
      single: false,
      name: 'DCAU/USDT.e',
      vault: false,
      strategyAddress: '0x3385ec3487e37Cb172e9c8342842fFba7222C01E',
      quoteTokenAddress: '0xF72Cc18218058722a3874b63487F1B4C82F92081',
      quoteStablePairAddress: '0x6BF04678E2a0Cb9dBEb41bf3e4FD7B0918DE0301',
      stableTokenAddress: '0x3eDCd20436B4485a8F8Ee107aC8e33855ac771A8',
      tokenPair: '0xF72Cc18218058722a3874b63487F1B4C82F92081/0x3eDCd20436B4485a8F8Ee107aC8e33855ac771A8',
      logo: 'assets/images/tokens/dcau-usdt.e.png'
    },
    {
      mcPid: 2,
      pid: 2,
      address: '0x675617dD8Abb94bb24DA9cb34cCE93aA936c1307',
      single: false,
      name: 'DCAU/MIM',
      vault: false,
      strategyAddress: '0xB1F7b1d9C2D9072E022F02826775DB6ca4571eeF',
      quoteTokenAddress: '0xF72Cc18218058722a3874b63487F1B4C82F92081',
      quoteStablePairAddress: '0x6BF04678E2a0Cb9dBEb41bf3e4FD7B0918DE0301',
      stableTokenAddress: '0x3eDCd20436B4485a8F8Ee107aC8e33855ac771A8',
      tokenPair: '0xF72Cc18218058722a3874b63487F1B4C82F92081/0x8Dd3B7bC1E226A96daD12490c4D0f11FFF179D11',
      logo: 'assets/images/tokens/dcau-mim.png'
    },
    {
      mcPid: 3,
      pid: 3,
      address: '0xF72Cc18218058722a3874b63487F1B4C82F92081',
      single: true,
      name: 'DCAU - SINGLE',
      vault: false,
      strategyAddress: '0x0000000000000000000000000000000000000000',
      quoteTokenAddress: '0xF72Cc18218058722a3874b63487F1B4C82F92081',
      quoteStablePairAddress: '0x6BF04678E2a0Cb9dBEb41bf3e4FD7B0918DE0301',
      stableTokenAddress: '0x3eDCd20436B4485a8F8Ee107aC8e33855ac771A8',
      logo: 'assets/images/tokens/dcau.png'
    },
    {
      mcPid: 4,
      pid: 4,
      address: '0x02E8deC98398F747f079E5a76DddE72cd1A49D9a',
      single: true,
      name: 'WAVAX - SINGLE',
      vault: false,
      strategyAddress: '0x0000000000000000000000000000000000000000',
      quoteTokenAddress: '0xF72Cc18218058722a3874b63487F1B4C82F92081',
      quoteStablePairAddress: '0x6BF04678E2a0Cb9dBEb41bf3e4FD7B0918DE0301',
      stableTokenAddress: '0x3eDCd20436B4485a8F8Ee107aC8e33855ac771A8',
      logo: 'assets/images/tokens/wavax.png'
    },
    {
      mcPid: 5,
      pid: 5,
      address: '0x32b9fA24C961F431fD2E12d2265576377085BF21',
      single: true,
      name: 'WBTC.e - SINGLE',
      vault: false,
      strategyAddress: '0x0000000000000000000000000000000000000000',
      quoteTokenAddress: '0xF72Cc18218058722a3874b63487F1B4C82F92081',
      quoteStablePairAddress: '0x6BF04678E2a0Cb9dBEb41bf3e4FD7B0918DE0301',
      stableTokenAddress: '0x3eDCd20436B4485a8F8Ee107aC8e33855ac771A8',
      logo: 'assets/images/tokens/wbtc.e.png'
    },
    {
      mcPid: 6,
      pid: 6,
      address: '0x451191fAbf495A016324550220D038F5E71760e1',
      single: true,
      name: 'WETH.e - SINGLE',
      vault: false,
      strategyAddress: '0x0000000000000000000000000000000000000000',
      quoteTokenAddress: '0xF72Cc18218058722a3874b63487F1B4C82F92081',
      quoteStablePairAddress: '0x6BF04678E2a0Cb9dBEb41bf3e4FD7B0918DE0301',
      stableTokenAddress: '0x3eDCd20436B4485a8F8Ee107aC8e33855ac771A8',
      logo: 'assets/images/tokens/weth.e.png'
    },
    {
      mcPid: 7,
      pid: 7,
      address: '0x3eDCd20436B4485a8F8Ee107aC8e33855ac771A8',
      single: true,
      name: 'USDT.e - SINGLE',
      vault: false,
      strategyAddress: '0x0000000000000000000000000000000000000000',
      quoteTokenAddress: '0xF72Cc18218058722a3874b63487F1B4C82F92081',
      quoteStablePairAddress: '0x6BF04678E2a0Cb9dBEb41bf3e4FD7B0918DE0301',
      stableTokenAddress: '0x3eDCd20436B4485a8F8Ee107aC8e33855ac771A8',
      logo: 'assets/images/tokens/usdt.e.png'
    },
    {
      mcPid: 8,
      pid: 8,
      address: '0xB2c502Ca09540DE3a2341a10a92F46939F8e8d0E',
      single: true,
      name: 'JOE - SINGLE',
      vault: false,
      strategyAddress: '0x0000000000000000000000000000000000000000',
      quoteTokenAddress: '0xF72Cc18218058722a3874b63487F1B4C82F92081',
      quoteStablePairAddress: '0x6BF04678E2a0Cb9dBEb41bf3e4FD7B0918DE0301',
      stableTokenAddress: '0x3eDCd20436B4485a8F8Ee107aC8e33855ac771A8',
      logo: 'assets/images/tokens/joe.png'
    },
    {
      mcPid: 9,
      pid: 9,
      address: '0x8Dd3B7bC1E226A96daD12490c4D0f11FFF179D11',
      single: true,
      name: 'MIM - SINGLE',
      vault: false,
      strategyAddress: '0x0000000000000000000000000000000000000000',
      quoteTokenAddress: '0xF72Cc18218058722a3874b63487F1B4C82F92081',
      quoteStablePairAddress: '0x6BF04678E2a0Cb9dBEb41bf3e4FD7B0918DE0301',
      stableTokenAddress: '0x3eDCd20436B4485a8F8Ee107aC8e33855ac771A8',
      logo: 'assets/images/tokens/mim.png'
    },
    {
      mcPid: 10,
      pid: 10,
      address: '0x66bCC0017D98787e46333F11cE6CD92D276133Eb',
      single: true,
      name: 'SPELL - SINGLE',
      vault: false,
      strategyAddress: '0x0000000000000000000000000000000000000000',
      quoteTokenAddress: '0xF72Cc18218058722a3874b63487F1B4C82F92081',
      quoteStablePairAddress: '0x6BF04678E2a0Cb9dBEb41bf3e4FD7B0918DE0301',
      stableTokenAddress: '0x3eDCd20436B4485a8F8Ee107aC8e33855ac771A8',
      logo: 'assets/images/tokens/spell.png'
    },
    {
      mcPid: 11,
      pid: 11,
      address: '0xaBd4Cd516eDd0E7D3347479Da9be5c60ba5D8dcf',
      single: true,
      name: 'TIME - SINGLE',
      vault: false,
      strategyAddress: '0x0000000000000000000000000000000000000000',
      quoteTokenAddress: '0xF72Cc18218058722a3874b63487F1B4C82F92081',
      quoteStablePairAddress: '0x6BF04678E2a0Cb9dBEb41bf3e4FD7B0918DE0301',
      stableTokenAddress: '0x3eDCd20436B4485a8F8Ee107aC8e33855ac771A8',
      logo: 'assets/images/tokens/time.png'
    }
  ],
  avax: [
    /** closed vault pools */
    {
      mcPid: 0,
      pid: 0,
      address: '0x81034A38a124A3290DC226798f34c6645B153a02',
      single: false,
      name: 'DCAU/WAVAX - Please Withdraw and Deposit in to compounding vault below to collect rewards',
      vault: true,
      vaultClosed: true,
      strategyAddress: '0x831b6a5983b503bEb7f55ec3f6A4583C37066Aa9',
      quoteTokenAddress: '0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30',
      quoteStablePairAddress: '0x605AAC9F0024A019F328074909874E3EE510f377',
      stableTokenAddress: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      tokenPair: '0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30/0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
      logo: 'assets/images/tokens/dcau-wavax.png'
    },
    {
      mcPid: 1,
      pid: 1,
      address: '0x605AAC9F0024A019F328074909874E3EE510f377',
      single: false,
      name: 'DCAU/USDT.e - Please Withdraw and Deposit in to compounding vault below to collect rewards',
      vault: true,
      vaultClosed: true,
      strategyAddress: '0xeF25f9a22DFc7f0Bfc8A8EEE185D74b1c1a827DE',
      quoteTokenAddress: '0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30',
      quoteStablePairAddress: '0x605AAC9F0024A019F328074909874E3EE510f377',
      stableTokenAddress: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      tokenPair: '0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30/0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      logo: 'assets/images/tokens/dcau-usdt.e.png'
    },
    {
      mcPid: 2,
      pid: 2,
      address: '0xbD941531c1234ed2f9dbbC79aEe019bA85c16640',
      single: false,
      name: 'DCAU/MIM - Please Withdraw and Deposit in to compounding vault below to collect rewards',
      vault: true,
      vaultClosed: true,
      strategyAddress: '0x2Cc9D1ec4878569a94B172dE0D07f24b48564324',
      quoteTokenAddress: '0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30',
      quoteStablePairAddress: '0x605AAC9F0024A019F328074909874E3EE510f377',
      stableTokenAddress: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      tokenPair: '0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30/0x130966628846BFd36ff31a822705796e8cb8C18D',
      logo: 'assets/images/tokens/dcau-mim.png'
    },
    /** vault pools  */
    {
      mcPid: 0,
      pid: 3,
      address: '0x81034A38a124A3290DC226798f34c6645B153a02',
      single: false,
      name: 'DCAU/WAVAX - VAULT',
      vault: true,
      vaultClosed: true,
      strategyAddress: '0xaA45BDdB5014Fd807923d93DfFcC7a1D1a125b49',
      quoteTokenAddress: '0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30',
      quoteStablePairAddress: '0x605AAC9F0024A019F328074909874E3EE510f377',
      stableTokenAddress: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      tokenPair: '0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30/0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
      logo: 'assets/images/tokens/dcau-wavax.png'
    },
    {
      mcPid: 1,
      pid: 4,
      address: '0x605AAC9F0024A019F328074909874E3EE510f377',
      single: false,
      name: 'DCAU/USDT.e - VAULT',
      vault: true,
      vaultClosed: true,
      strategyAddress: '0x07734A573342B801D3d03A1c80Fe4682ebbB85aF',
      quoteTokenAddress: '0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30',
      quoteStablePairAddress: '0x605AAC9F0024A019F328074909874E3EE510f377',
      stableTokenAddress: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      tokenPair: '0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30/0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      logo: 'assets/images/tokens/dcau-usdt.e.png'
    },
    {
      mcPid: 2,
      pid: 6,
      address: '0xbD941531c1234ed2f9dbbC79aEe019bA85c16640',
      single: false,
      name: 'DCAU/MIM - VAULT',
      vault: true,
      vaultClosed: true,
      strategyAddress: '0xCb8647BD18A2bAF94CD97E592442Af3B737101cd',
      quoteTokenAddress: '0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30',
      quoteStablePairAddress: '0x605AAC9F0024A019F328074909874E3EE510f377',
      stableTokenAddress: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      tokenPair: '0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30/0x130966628846BFd36ff31a822705796e8cb8C18D',
      logo: 'assets/images/tokens/dcau-mim.png'
    },
    /** MC pools */
    {
      mcPid: 0,
      pid: 0,
      address: '0x81034A38a124A3290DC226798f34c6645B153a02',
      single: false,
      name: 'DCAU/WAVAX',
      vault: false,
      vaultClosed: true,
      strategyAddress: '0x0000000000000000000000000000000000000000',
      quoteTokenAddress: '0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30',
      quoteStablePairAddress: '0x605AAC9F0024A019F328074909874E3EE510f377',
      stableTokenAddress: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      tokenPair: '0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30/0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
      logo: 'assets/images/tokens/dcau-wavax.png'
    },
    {
      mcPid: 1,
      pid: 1,
      address: '0x605AAC9F0024A019F328074909874E3EE510f377',
      single: false,
      name: 'DCAU/USDT.e',
      vault: false,
      vaultClosed: true,
      strategyAddress: '0x0000000000000000000000000000000000000000',
      quoteTokenAddress: '0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30',
      quoteStablePairAddress: '0x605AAC9F0024A019F328074909874E3EE510f377',
      stableTokenAddress: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      tokenPair: '0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30/0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      logo: 'assets/images/tokens/dcau-usdt.e.png'
    },
    {
      mcPid: 2,
      pid: 2,
      address: '0xbD941531c1234ed2f9dbbC79aEe019bA85c16640',
      single: false,
      name: 'DCAU/MIM',
      vault: false,
      vaultClosed: true,
      strategyAddress: '0x0000000000000000000000000000000000000000',
      quoteTokenAddress: '0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30',
      quoteStablePairAddress: '0x605AAC9F0024A019F328074909874E3EE510f377',
      stableTokenAddress: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      tokenPair: '0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30/0x130966628846BFd36ff31a822705796e8cb8C18D',
      logo: 'assets/images/tokens/dcau-mim.png'
    },
    {
      mcPid: 3,
      pid: 3,
      address: '0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30',
      single: true,
      name: 'DCAU',
      vault: false,
      vaultClosed: true,
      strategyAddress: '0x0000000000000000000000000000000000000000',
      quoteTokenAddress: '0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30',
      quoteStablePairAddress: '0x605AAC9F0024A019F328074909874E3EE510f377',
      stableTokenAddress: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      logo: 'assets/images/tokens/dcau.png'
    },
    {
      mcPid: 4,
      pid: 4,
      address: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
      single: true,
      name: 'WAVAX - 4% Deposit',
      vault: false,
      vaultClosed: true,
      strategyAddress: '0x0000000000000000000000000000000000000000',
      quoteTokenAddress: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
      quoteStablePairAddress: '0xeD8CBD9F0cE3C6986b22002F03c6475CEb7a6256',
      stableTokenAddress: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      logo: 'assets/images/tokens/wavax.png'
    },
    {
      mcPid: 5,
      pid: 5,
      address: '0x50b7545627a5162F82A992c33b87aDc75187B218',
      single: true,
      vaultClosed: true,
      name: 'WBTC.e - 4% Deposit',
      vault: false,
      strategyAddress: '0x0000000000000000000000000000000000000000',
      quoteTokenAddress: '0x50b7545627a5162F82A992c33b87aDc75187B218',
      quoteStablePairAddress: '0xB8D5E8a9247db183847c7D79af9C67F6aeF759f7',
      stableTokenAddress: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      logo: 'assets/images/tokens/wbtc.e.png'
    },
    {
      mcPid: 6,
      pid: 6,
      address: '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
      single: true,
      name: 'WETH.e - 4% Deposit',
      vault: false,
      vaultClosed: true,
      strategyAddress: '0x0000000000000000000000000000000000000000',
      quoteTokenAddress: '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
      quoteStablePairAddress: '0xbe1b87f47fde3f338aa3aa98b85435e1709dfd06',
      stableTokenAddress: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      logo: 'assets/images/tokens/weth.e.png'
    },
    {
      mcPid: 7,
      pid: 7,
      address: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      single: true,
      name: 'USDT.e - 4% Deposit',
      vault: false,
      vaultClosed: true,
      strategyAddress: '0x0000000000000000000000000000000000000000',
      quoteTokenAddress: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      quoteStablePairAddress: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      stableTokenAddress: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      logo: 'assets/images/tokens/usdt.e.png'
    },
    {
      mcPid: 8,
      pid: 8,
      address: '0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd',
      single: true,
      name: 'JOE - 4% Deposit',
      vault: false,
      vaultClosed: true,
      strategyAddress: '0x0000000000000000000000000000000000000000',
      quoteTokenAddress: '0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd',
      quoteStablePairAddress: '0x1643de2efB8e35374D796297a9f95f64C082a8ce',
      stableTokenAddress: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      logo: 'assets/images/tokens/joe.png'
    },
    {
      mcPid: 9,
      pid: 9,
      address: '0x130966628846BFd36ff31a822705796e8cb8C18D',
      single: true,
      name: 'MIM - 4% Deposit',
      vault: false,
      vaultClosed: true,
      strategyAddress: '0x0000000000000000000000000000000000000000',
      quoteTokenAddress: '0x130966628846BFd36ff31a822705796e8cb8C18D',
      quoteStablePairAddress: '0x130966628846BFd36ff31a822705796e8cb8C18D',
      stableTokenAddress: '0x130966628846BFd36ff31a822705796e8cb8C18D',
      logo: 'assets/images/tokens/mim.png'
    },
    {
      mcPid: 10,
      pid: 10,
      address: '0xCE1bFFBD5374Dac86a2893119683F4911a2F7814',
      single: true,
      name: 'SPELL - 4% Deposit',
      vault: false,
      vaultClosed: true,
      strategyAddress: '0x0000000000000000000000000000000000000000',
      quoteTokenAddress: '0xCE1bFFBD5374Dac86a2893119683F4911a2F7814',
      quoteStablePairAddress: '0x271cE7eED3E0C8f74ba0a0fF9Dd9Fa33744b1FF5', // MIM_SPELL pair
      stableTokenAddress: '0x130966628846BFd36ff31a822705796e8cb8C18D',
      logo: 'assets/images/tokens/spell.png'
    },
    {
      mcPid: 11,
      pid: 11,
      address: '0xb54f16fB19478766A268F172C9480f8da1a7c9C3',
      single: true,
      name: 'TIME - 4% Deposit',
      vault: false,
      vaultClosed: true,
      strategyAddress: '0x0000000000000000000000000000000000000000',
      quoteTokenAddress: '0xb54f16fB19478766A268F172C9480f8da1a7c9C3',
      quoteStablePairAddress: '0x113f413371fC4CC4C9d6416cf1DE9dFd7BF747Df',
      stableTokenAddress: '0x130966628846BFd36ff31a822705796e8cb8C18D',
      logo: 'assets/images/tokens/time.png'
    }
  ]
};
